import React, { useState, useEffect } from 'react';
import './StyleCalculator.css';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const ExpensesCalculator = ({ setTotalExpenses }) => {
  const [expensesData, setExpensesData] = useState({
    homeAndContents: {
      rent: 0,
      groundRent: 0,
      mortgage: 0,
      mortgageEndowment: 0,
      securedLoans: 0,
      councilTax: 0,
      applianceRental: 0,
      tvLicence: 0,
      otherCosts: 0,
    },
    utilities: {
      electricity: 0,
      gas: 0,
      otherCosts: 0,
      otherExpenditure: 0,
    },
    water: {
      waterSupply: 0,
      waterWaste: 0,
    },
    communicationsAndLeisure: {
      phoneInternetTV: 0,
      mobilePhone: 0,
      hobbies: 0,
      gifts: 0,
      pocketMoney: 0,
      newspapers: 0,
      otherCosts: 0,
    },
    transportAndTravel: {
      publicTransport: 0,
      hirePurchase: 0,
      carInsurance: 0,
      roadTax: 0,
      motMaintenance: 0,
      breakdownCover: 0,
      fuelParkingTolls: 0,
      otherCosts: 0,
    },
    foodAndHousekeeping: {
      groceries: 0,
      babyItems: 0,
      schoolMeals: 0,
      laundry: 0,
      alcohol: 0,
      smokingProducts: 0,
      vetBills: 0,
      houseRepairs: 0,
      otherCosts: 0,
    },
    pensionsAndInsurances: {
      pensionPayments: 0,
      lifeInsurance: 0,
      mortgageProtectionInsurance: 0,
      buildingsInsurance: 0,
      healthInsurance: 0,
      otherCosts: 0,
    },
    personalCosts: {
      clothingFootwear: 0,
      hairdressing: 0,
      toiletries: 0,
      otherCosts: 0,
    },
    careAndHealthCosts: {
      childcareCosts: 0,
      adultCareCosts: 0,
      childMaintenance: 0,
      prescriptions: 0,
      dentalOptical: 0,
      otherCosts: 0,
    },
    schoolCosts: {
      schoolUniform: 0,
      afterSchoolClubs: 0,
      otherCosts: 0,
    },
    professionalCosts: {
      professionalCourses: 0,
      unionFees: 0,
      professionalFees: 0,
      other: 0,
    },
    savings: {
      monthlySaving: 0,
    },
    otherEssentialCosts: {
      otherCosts: 0,
      taxNationalInsurance: 0,
    },
  });

  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');
    setExpensesData((prevData) => {
      let newData = { ...prevData };
      if (keys.length === 2) {
        newData[keys[0]][keys[1]] = Number(value);
      }
      return newData;
    });
  };

  const toggleAccordion = (section) => {
    setActiveAccordion(activeAccordion === section ? null : section);
  };

  const totalExpenses = Object.values(expensesData).reduce((acc, category) => {
    return acc + Object.values(category).reduce((catAcc, val) => catAcc + val, 0);
  }, 0);

  useEffect(() => {
    setTotalExpenses(totalExpenses);
  }, [totalExpenses, setTotalExpenses]);


  return (
    <div className='container'>
      <h3>Expenses Calculator</h3>
      <div className="fields-layout">
        {[
          'homeAndContents',
          'utilities',
          'water',
          'communicationsAndLeisure',
          'transportAndTravel',
          'foodAndHousekeeping',
          'pensionsAndInsurances',
          'personalCosts',
          'careAndHealthCosts',
          'schoolCosts',
          'professionalCosts',
          'savings',
          'otherEssentialCosts',
        ].map((section) => (
          <div key={section}>
            <button className='expand-collapse-btn' onClick={() => toggleAccordion(section)}>
              {section.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())} <span accordion-status>{activeAccordion === section ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</span>
            </button>
            {activeAccordion === section && (
              <div className="accordion-content">
                {Object.entries(expensesData[section]).map(([key, value]) => (
                  <div key={key} className='input-fields'>
                    <label>{key.replace(/([A-Z])/g, ' $1')}: </label>
                    <input
                      type="number"
                      name={`${section}.${key}`}
                      value={value}
                      onChange={handleChange}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpensesCalculator;
