import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './pages/Navbar';
import Home from './pages/Home/Home';
import IncomeCalculator from './pages/Calculator/IncomeCalculator';
import Footer from './pages/Footer/Footer';
import ExpensesCalculator from './pages/Calculator/ExpensesCalculator';
import React, { useState } from 'react';
import Summary from './pages/Calculator/Summary';
import CookieConsent from './components/CookieConsent/CookieConsent';

function App() {
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  return (
      <div>
        <Navbar /> 
        <div id="home">
          <Home />
        </div>
        <div id="calculators" className='container debt-calc-container'>
        <IncomeCalculator setTotalIncome={setTotalIncome} />
        <ExpensesCalculator setTotalExpenses={setTotalExpenses} />
        <Summary totalIncome={totalIncome} totalExpenses={totalExpenses} />
        </div>
        <Footer />
        <CookieConsent />
      </div>
  );
}

export default App;
