import React, { useState, useEffect } from 'react';
import './CookieConsent.css';
import { RxCross2 } from "react-icons/rx";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const closeCookies = () => {
    setIsVisible(false);
  }

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <button className='close-btn' onClick={closeCookies}>
        <RxCross2  color='#FFFFFF' />
      </button>
      <h4>We use cookies</h4>
      <p>Cookies help us deliver the best experience on our website, you agree to the use of cookies.</p>
      <button className='submit' onClick={acceptCookies}>Got it!</button>
    </div>
  );
};

export default CookieConsent;
