import React, { useState, useEffect } from 'react';
import './StyleCalculator.css'
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const IncomeCalculator = ({ setTotalIncome }) => {
  const [incomeData, setIncomeData] = useState({
    wages: { salary: 0, partnerSalary: 0, clientEarnings: 0, partnerEarnings: 0 },
    benefits: {
      universalCredit: 0,
      jobseekersAllowanceIncome: 0,
      jobseekersAllowanceContribution: 0,
      incomeSupport: 0,
      workingTaxCredit: 0,
      childTaxCredit: 0,
      childBenefit: 0,
      esaOrSsp: 0,
      disabilityBenefits: 0,
      carersAllowance: 0,
      housingAllowance: 0,
      councilTaxSupport: 0,
      otherBenefits: 0,
    },
    pensions: { statePension: 0, privatePension: 0, pensionCredit: 0, otherPensions: 0 },
    other: { maintenance: 0, boarders: 0, nonDependants: 0, studentLoan: 0 },
  });

  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');
    setIncomeData((prevData) => {
      let newData = { ...prevData };
      if (keys.length === 2) {
        newData[keys[0]][keys[1]] = Number(value);
      }
      return newData;
    });
  };

  const toggleAccordion = (section) => {
    setActiveAccordion(activeAccordion === section ? null : section);
  };

  const totalIncome = Object.values(incomeData).reduce((acc, category) => {
    return acc + Object.values(category).reduce((catAcc, val) => catAcc + val, 0);
  }, 0);

  useEffect(() => {
    setTotalIncome(totalIncome);
  }, [totalIncome, setTotalIncome]);

  return (
    <div className='container'>
      <h3>Income Calculator</h3>
      <div className="fields-layout">
        {['wages', 'benefits', 'pensions', 'other'].map((section) => (
          <div key={section}>
            <button className='expand-collapse-btn' onClick={() => toggleAccordion(section)}>
              {section.charAt(0).toUpperCase() + section.slice(1)} <span accordion-status>{activeAccordion === section ? <TiArrowSortedUp /> : <TiArrowSortedDown />}</span>
            </button>
            {activeAccordion === section && (
              <div className="accordion-content">
                {Object.entries(incomeData[section]).map(([key, value]) => (
                  <div key={key} className='input-fields'>
                    <label>{key.replace(/([A-Z])/g, ' $1')}: </label>
                    <input
                      type="number"
                      name={`${section}.${key}`}
                      value={value}
                      onChange={handleChange}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncomeCalculator;
