import React from 'react';
import './Summary.css'

const Summary = ({ totalIncome, totalExpenses }) => {
  const balance = totalIncome - totalExpenses;

  return (
    balance !== 0 ? (
    <div className="container fixed-footer">
      <div className="footer-item income-bg">Total Household Income:<br/>£{totalIncome.toFixed(2)}</div>
      <div className="footer-item expense-bg">Total Expenditure:<br/>£{totalExpenses.toFixed(2)}</div>
      <div className="footer-item balance-bg">Balance:<br/>£{balance.toFixed(2)}</div>
    </div>
    ) : null
  );
};

export default Summary;
